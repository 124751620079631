/* Custom header Color.css */
.header-custom-blue {
  /*background-color: blue;*/
  color: blue;
  /*font-weight: bold;*/
}

.sum1{
  color: peru;
}

.sumall{
  color:fuchsia;
}

.header-custom {
  font-size: 0.85rem; /* Adjust as needed */
  font-weight: bold;
}

.last-row {
  background-color: #f0f0f0; /* สีพื้นหลังสำหรับแถวสุดท้าย */
  font-weight: bold; /* ทำตัวอักษรให้หนาขึ้น (ถ้าต้องการ) */
}